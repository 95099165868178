import axios from 'axios';
import config from 'Config';
import UserSession from 'services/UserSession';

const CryptoJS = require("crypto-js");

const axiosConfig = {
    baseURL: config.apiUrl,
    timeout: 30000,
};

const axiosPrivateInstance = axios.create(axiosConfig);
const axiosPublicInstance = axios.create(axiosConfig);

// Request interceptor for API calls
axiosPrivateInstance.interceptors.request.use(
    async config => {
        config.headers = { 
            'Authorization': `Bearer ${UserSession.getLocalAccessToken()}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        return config;
    },
    error => {
        Promise.reject(error)
    }
);

// Response interceptor for API calls
axiosPrivateInstance.interceptors.response.use((response) => {
    return response
  }, async function (error) {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const access_token = await refreshAccessToken();            
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;        
        return axiosPrivateInstance(originalRequest);
    }
    return Promise.reject(error);
});

// Auth

const refreshAccessToken = async () => {
    try {
        const response = await axiosPublicInstance.post("/auth/token", {
            refreshToken: UserSession.getLocalRefreshToken(),
        });
        
        const { token, assetsToken } = response.data;

        if (!token) {
            UserSession.removeUser();
            window.location.href = '/';
        }

        UserSession.updateLocalAccessToken(token, assetsToken);

        return token;
    } catch (error) {
        window.location.href = '/logout';
    }
}

export const getIp = () => axios.get(
    'https://api.ipify.org/?format=json'
);

export const doLogin = (email, password, ip) => axiosPublicInstance.post(
    '/auth/login', 
    {
        "email": email,
        "password": password,
        "ip": ip
    }
);

export const doLogout = (refreshToken) => axiosPublicInstance.post(
    '/auth/logout', 
    {
        "refreshToken": refreshToken
    }
);

export const verifyOtp = (auth2Token, otpCode) => axiosPublicInstance.post(
    '/auth/verify-otp', 
    {
        "auth2Token": auth2Token,
        "otpCode": otpCode
    }
);

export const signUp = (email, password, firstname, lastname) => axiosPublicInstance.post(
    '/auth/signup', 
    {
        "email": email,
        "password": password,
        "firstname": firstname,
        "lastname": lastname
    }
)

export const resetPassword = (email) => axiosPublicInstance.post(
    '/auth/reset-password', 
    {
        "email": email,
    }
);

// Profile

export const getUsers = () => axiosPrivateInstance.get(
    '/user/all', 
    {}
);

export const getProfile = () => axiosPrivateInstance.get(
    '/user', 
    {}
);

export const getOtpConfig = () => axiosPrivateInstance.get(
    '/user/otp-config', 
    { responseType: 'blob' }
);

export const updateProfileInformation = (firstname, lastname) => axiosPrivateInstance.post(
    '/user/profile', 
    {
        'firstname': firstname,
        'lastname': lastname
    }
);

export const updateProfileMail = (mail) => axiosPrivateInstance.post(
    '/user/email', 
    {
        'email': mail
    }
);

export const updateProfilePassword = (previousPassword, newPassword) => axiosPrivateInstance.post(
    '/user/password', 
    {
        'previousPassword': previousPassword,
        'password': newPassword
    }
);

export const updateProfileDoubleAuth = (enabled) => axiosPrivateInstance.post(
    '/user/double-auth', 
    {
        'doubleAuthEnabled': enabled,
    }
);

export const resetProfilePassword = () => axiosPrivateInstance.post(
    '/user/reset-password', 
    {}
);

export const deleteProfile = () => axiosPrivateInstance.delete(
    '/user',
    {}
)

export const enableUser = (id, enable) => axiosPrivateInstance.put(
    `/user/${id}`,
    {
        "enabled": enable
    }
)

export const deleteUser = (id) => axiosPrivateInstance.delete(
    `/user/${id}`,
    {}
)

// Editors

export const getEditors = () => axiosPrivateInstance.get(
    '/editor/all',
    {
        
    }
)

// Categories

export const getCategories = () => axiosPrivateInstance.get(
    '/category/all',
    {
        
    }
)

export const getCategory = (categoryId) => axiosPrivateInstance.get(
    `/category/${categoryId}`,
    {
        
    }
)

export const createCategory = (name, code, rwId, description, rank) => axiosPrivateInstance.post(
    `/category`,
    {
        "name": name,
        "code": code,
        "rwId": rwId,
        "description": description,
        "rank": rank
    }
)

export const updateCategory = (categoryId, name, code, rwId, description, rank) => axiosPrivateInstance.put(
    `/category/${categoryId}`,
    {
        "name": name,
        "code": code,
        "rwId": rwId,
        "description": description,
        "rank": rank
    }
)

export const deleteCategory = (categoryId) => axiosPrivateInstance.delete(
    `/category/${categoryId}`,
    {
        
    }
)

export const getSubCategories = () => axiosPrivateInstance.get(
    '/subcategory/all',
    {
        
    }
)

// Courses

export const getCourses = (
    page = 1, 
    limit = 20,
    contentTypes = [],
    categories = [], 
    subcategories = [], 
    difficulties = [],
    sort = "-released_at",
    query = ''
) => axiosPrivateInstance.get(
    '/course/all',
    { params : {
        page: page,
        limit: limit,
        contentTypes: contentTypes,
        categories: categories,
        subcategories: subcategories,
        difficulties: difficulties,
        sort: sort,
        q: query
    } }
)

export const getCourse = (courseId) => axiosPrivateInstance.get(
    `/course/one/${courseId}`,
    {}
)

export const getBookmarkedCourses = () => axiosPrivateInstance.get(
    `/course/all/bookmarked`,
    {}
)

export const getCompletedCourses = () => axiosPrivateInstance.get(
    `/course/all/completed`,
    {}
)

export const updateCourseUserProgress = (courseId, completed) => axiosPrivateInstance.post(
    `/course/progress`,
    {
        "courseId": courseId,
        "completed": completed
    }
)

export const updateCourseUserBookmark = (courseId, bookmarked) => axiosPrivateInstance.post(
    `/course/bookmark`,
    {
        "courseId": courseId,
        "bookmarked": bookmarked
    }
)

// Books

export const getCategoryBooks = (categoryId) => axiosPrivateInstance.get(
    `/category/${categoryId}/books`,
    {
        
    }
)

export const getBooks = (query = "", categories = null, sort = "") => axiosPrivateInstance.get(
    '/book/all',
    { params : {
        query: query,
        categories: categories,
        sort: sort
    } }
)

export const getBookEditions = (bookId) => axiosPrivateInstance.get(
    `/book/${bookId}/edition`,
    {

    }
)

export const getRecommendedBooks = () => axiosPrivateInstance.get(
    '/book/recommended',
    {
        
    }
)

export const getReadingBooks = () => axiosPrivateInstance.get(
    '/book/reading',
    {
        
    }
)

export const getRelatedBooks = (bookId) => axiosPrivateInstance.get(
    `/book/related/${bookId}`,
    {
        
    }
)

export const getBook = (bookId, version = null) => axiosPrivateInstance.get(
    `/book/one/${bookId}${version ? `?version=${version}` : ''}`,
    {}
)

export const getBookChapter = (bookId, chapterId, version = null) => axiosPrivateInstance.get(
    `/book/${bookId}/chapter/${chapterId}${version ? `?version=${version}` : ''}`,
    {}
)

export const searchInBook = (bookId, query) => axiosPrivateInstance.get(
    `/book/one/${bookId}/search?query=${query}`,
    {}
)

export const updateBookUserProgress = (chapterId, completed) => axiosPrivateInstance.post(
    `/book/progress`,
    {
        "chapterId": chapterId,
        "completed": completed
    }
)

export const setBookAsCompleted = (bookId, version) => axiosPrivateInstance.post(
    `/book/progress/complete`,
    {        
        "bookId": bookId,
        "version": version
    }
)

export const getBookNotes = (bookId) => axiosPrivateInstance.get(
    `/book/${bookId}/note`,
    {}
)

export const createBookNote = (bookId, chapterId, text, color) => axiosPrivateInstance.post(
    `/book/${bookId}/note`,
    {
        "chapterId": chapterId,
        "text": text,
        "color": color
    }
)

export const updateBookNote = (bookId, commentId, text, color) => axiosPrivateInstance.put(
    `/book/${bookId}/note/${commentId}`,
    {        
        "text": text,
        "color": color
    }
)

export const deleteBookNote = (bookId, commentId) => axiosPrivateInstance.delete(
    `/book/${bookId}/note/${commentId}`,
    {        
        
    }
)

export const deleteBookUserProgress = (bookId, version) => axiosPrivateInstance.delete(
    `/book/progress`,
    { 
        data: {
            "bookId": bookId,
            "version": version
        }
    }
)

export const createBook = (data) => axiosPrivateInstance.post(
    `/book`,
    data
)

export const updateBook = (bookId, data) => axiosPrivateInstance.put(
    `/book/${bookId}`,
    data
)

export const deleteBook = (bookId) => axiosPrivateInstance.delete(
    `/book/one/${bookId}`,
    {}
)

export const deleteBookEdition = (bookId, version) => axiosPrivateInstance.delete(
    `/book/one/${bookId}/${version}`,
    {}
)

export const deleteBookSection = (bookSectionId) => axiosPrivateInstance.delete(
    `/book/section/${bookSectionId}`,
    {}
)

export const deleteBookChapter = (bookChapterId) => axiosPrivateInstance.delete(
    `/book/chapter/${bookChapterId}`,
    {}
)

export const generateNewEdition = (bookId, url) => axiosPrivateInstance.post(
    `/book/generate-book`,
    {
        bookId: bookId,
        url: url
    },
    {
        timeout: 60000
    }
)

export const generateChapter = (url) => axiosPrivateInstance.post(
    `/book/generate-chapter`,
    {
        url: url
    }
)

// Media
export const getMedia = (url) => axiosPrivateInstance.get(
    `/file/cache?url=${encodeURIComponent(url)}`, 
    { responseType: 'blob' }
)
export const getPlayerUrl = (url) => {
    let signature = CryptoJS.MD5(url).toString();
    signature = CryptoJS.SHA512(signature).toString()
    return `/file/player?token=${UserSession.getLocalAssetsToken()}&signature=${signature}&url=${encodeURIComponent(url)}`;
}

// Admin:Stats
export const getAdminOverview = () => axiosPrivateInstance.get(
    `/admin/overview`,
    {}
)

// Admin:SSO
export const getRwSso = () => axiosPrivateInstance.get(
    `/admin/sso/${config.ssoRwCode}`,
    {}
)
export const createRwSso = (data) => axiosPrivateInstance.post(
    `/admin/sso`,
    {
        code: config.ssoRwCode,
        params: Object.keys(data).map((key) => ({
            code: key,
            value: data[key]
        }))
    }
)
export const updateRwSso = (id, data) => axiosPrivateInstance.put(
    `/admin/sso/${id}`,
    {
        id: id,
        code: config.ssoRwCode,
        params: Object.keys(data).map((key) => ({
            code: key,
            value: data[key]
        }))
    }
)
export const getRwSsoState = () => axiosPrivateInstance.get(
    `/admin/rw-sso/state`,
    {}
)
export const getRwSsoUrl = () => axiosPrivateInstance.get(
    `/admin/rw-sso/url`,
    {}
)
export const updateRwSession = (payload) => axiosPrivateInstance.post(
    `/admin/rw-sso/session`,
    {
        payload: payload
    }
)
export const deleteRwSession = (payload) => axiosPrivateInstance.delete(
    `/admin/rw-sso/session`,
    {}
)